export const FEATURES = ['terms', 'unaHideNotifyOperator', 'feesInvoicesHideMenu'] as const;
export type FeatureName = (typeof FEATURES)[number];
export type FeaturesConfig = Partial<Record<FeatureName, boolean>>;

export interface ConfigState {
  features?: FeaturesConfig;
  analytics?: {
    gtmContainerId: string;
  };
  keycloakServerUrl?: string;
  subsistenceFeesRunTriggerDate?: string;
}

export const initialState: ConfigState = {
  features: {
    unaHideNotifyOperator: false,
    feesInvoicesHideMenu: false,
  },
  analytics: {
    gtmContainerId: '',
  },
  subsistenceFeesRunTriggerDate: '',
};
